<template>
  <div class='w-100 h-100'>
    <Welcome v-if='mode == "welcome"' :model='model' />
    <ShowScreen v-if='mode == "show"' :model='model' @toggle-fullscreen="$emit('toggle-fullscreen')" />
    <NewAsset v-else-if='mode == "new_asset"' :model='model' />
    <EditAsset v-else-if='mode == "edit_asset"' :model='model' />
    <div class='w-100 h-100' v-else>
      UNKNOWN MODE: {{ model.mode }}
    </div>
  </div>
</template>

<script>

import Welcome from "./welcome_screen.vue"
import ShowScreen from "./show_screen.vue"
import NewAsset from "./new_asset_screen.vue"
import EditAsset from "./edit_asset_screen.vue"

export default {
  components: { Welcome, ShowScreen, NewAsset, EditAsset },
  props: [ "model" ],
  computed: {
    mode() { return this.model.mode },
    isScript() { return this.model?.currentEditable?.isScript() },
    isBlob() { return this.model?.currentEditable?.isBlob() },
  }
}

</script>