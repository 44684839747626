<template>
  <div class='form-group'>
    <label :for='fieldName'>{{ label }}</label>

    <input
      type='file'
      :id='fieldName'
      :class='classes'
      @input='$emit("input", getFieldValue())'
      ref='field'
      v-if='isFileField'/>
    <input
      :type='type'
      :id='fieldName'
      :class='classes'
      :value='value'
      @keydown.prevent.enter='$emit("submit")'
      @input='$emit("input", getFieldValue())'
      ref='field'
      v-else />
    <p v-if='hasError' class='invalid-feedback'>
      {{ error }}
    </p>
  </div>
</template>

<script>

export default {
  props: {
    label: String,
    value: {},
    error: String,
    type: { type: String, default: "text" },
  },
  computed: {
    isFileField() { return this.type == "file" },
    fieldName() { return `vue-field-${this._uid}` },
    hasError() { return !!this.error },
    classes() {
      const errorClass = {"is-invalid": this.hasError}

      if (this.isFileField) {
        return ["form-control-file", errorClass]
      } else {
        return ["form-control", errorClass]
      }
    }
  },
  methods: {
    focus() {
      this.$refs.field.focus()
    },
    getFieldValue() {
      const field = this.$refs.field

      if (this.isFileField) {
        return field.files[0]
      } else {
        return field.value
      }
    }
  }
}

</script>