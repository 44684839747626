<template>
  <div>
    <div ref='canvas' class='w-100 h-100'></div>
  </div>
</template>

<script>

import { monacoLazyPromise } from "../../../vendor/monaco"
import throttle from 'lodash.throttle'

export default {
  props: [ "value", "language" ],
  data() {
    return {
      monaco: null,
      editor: null,
      editorValue: "",

      resizeListener: null,
    }
  },
  computed: {
    editorOptions() {
      return
    }
  },
  mounted() {
    this.installMonaco()
    this.installResizeListener()
  },
  beforeDestroy() {
    this.uninstallResizeListener()
    this.uninstallMonaco()
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        const editorNeedsSync = this.editorValue !== this.value
        this.editorValue = this.value

        if (this.editor && editorNeedsSync) {
          this.editor.setValue(this.value)
          this.editor.revealLine(0);
        }
      }
    },
    language: {
      immediate: true,
      handler() {
        if (!this.editor) { return };

        this.monaco.editor.setModelLanguage(this.editor.getModel(), this.language)
      }
    },
  },
  methods: {
    // ==========
    // = Monaco =
    // ==========
    installMonaco() {
      monacoLazyPromise().then((monaco) => {
        this.monaco = monaco

        const editorOptions = {
          value: this.value,
          language: this.language,
          tabSize: 2,
          autoIndent: "full",
          trimAutoWhitespace: true,
          wordWrap: true,
        }


        this.editor = monaco.editor.create(this.$refs.canvas, editorOptions)
        this.editor.onDidChangeModelContent(this.onEditorChanged.bind(this))
      })
    },

    uninstallMonaco() {
      if (!this.editor) { return };

      this.editor.dispose()
    },

    // ===================
    // = Resize Handling =
    // ===================

    installResizeListener() {
      this.resizeListener = throttle(() => this.onResize(), 100, { trailing: true })

      window.addEventListener("resize", this.resizeListener)
    },

    uninstallResizeListener() {
      if (this.resizeListener) {
        window.removeEventListener("resize", this.resizeListener)
      }
    },

    layout() {
      if (this.editor) {
        this.editor.layout()
      }
    },


    onResize() {
      this.layout()
    },

    // ==========
    // = Events =
    // ==========
    onEditorChanged() {
      const value = this.editor.getValue().replace(/\r\n/, "\n")

      this.editorValue = value
      this.$emit("input", value)
    },

  }
}

</script>