<template>

  <div>
    {{ assetType }}
    <SerializedInput :key-path='keyPathFor("id")' :value='asset.id' />
    <SerializedInput :key-path='keyPathFor("_destroy")' :value='serializeBoolean(asset.isDestroyed())' />
    <SerializedInput :key-path='keyPathFor("path")' :value='asset.path' />
    <SerializedInput :key-path='keyPathFor("assetable_type")' :value='asset.apiType()' v-if='isNew'/>
    <SerializedInput :key-path='keyPathFor("assetable_attributes", "source")' :value='asset.source' v-if='isScript' />
    <SerializedInput :key-path='keyPathFor("assetable_attributes", "language")' :value='asset.language' v-if='isScript' />
    <SerializedInput :key-path='keyPathFor("assetable_attributes", "file")' :value='asset.uploadId' v-if='isUploaded' />
  </div>

</template>

<script>

import SerializedInput from "./serialized_input.vue"

export default {
  components: { SerializedInput },
  props: [ "keyPath", "asset" ],
  computed: {
    isNew() { return !this.asset.isPersisted() },
    assetType() { return this.isScript ? "Script" : "Blob" },
    isScript() { return this.asset.isScript() },
    isBlob() { return this.asset.isBlob() },
    isUploaded() { return this.isBlob && this.asset.isUploaded() }
  },
  methods: {
    keyPathFor(...path) {
      const { keyPath } = this

      return [...keyPath, ...path]
    },
    serializeBoolean(bool) {
      if (bool) {
        return "1"
      } else {
        return "0"
      }
    }
  }
}

</script>