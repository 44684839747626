import { ScriptAsset, BlobAsset } from "../models"

function classForType(type) {
  switch (type) {
  case "ScriptAsset": return ScriptAsset
  case "BlobAsset": return BlobAsset
  default:
    throw `Unknown model type: ${type}`
  }
}

export function wrapAsset(attributes) {
  const klass = classForType(attributes.assetable_type)

  return klass.load_from_api(attributes)
}

export function wrapAssets(assets) {
  return assets.map((attributes) => wrapAsset(attributes))
}

