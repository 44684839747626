<template>
  <div class='h-100 w-100' @drop.prevent.stop='handleDrop' @dragover.prevent='handleDragOver' >
    <div :class='["w-100 h-100 align-items-center justify-content-center", { "d-none": !showDropzone, "d-flex": showDropzone}]' @dragleave='showDropzone = false'>
      <slot name='dropzone' @dragleave.stop>
        <div class='m-2 rounded border-2'>
          Drop Here!
        </div>
      </slot>
    </div>
    <div :class='["h-100 w-100", { "d-none": showDropzone }]'>
      <slot  />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      showDropzone: false
    }
  },
  methods: {
    handleDragOver(e) {
      this.showDropzone = true
    },
    handleDrop(e) {
      this.showDropzone = false
      this.$emit("drop", this.getFiles(e))
    },

    getFiles(e) {
      const { items } = e.dataTransfer

      if (!items.length) { return null }

      return [...items].map((item) => item.getAsFile())
    },
  }
}

</script>
