let monacoPromise = null;

function provideCompletions(monaco, CompletionProvider) {
  const completionProvider = new CompletionProvider(monaco)

  monaco.languages.registerCompletionItemProvider("ruby", completionProvider)
}

function configure(monaco) {
  import("./completion_provider").then((module) => provideCompletions(monaco, module.default))

  return monaco
}

export function monacoLazyPromise() {
  if (!monacoPromise) {
    monacoPromise = import("monaco-editor").then((monaco) => configure(monaco))
  }

  return monacoPromise
}

export function languageFromExtension(monaco, extension) {
  const languages = monaco.languages.getLanguages()

  let identifiedLanguage = "plaintext"

  if (extension == ".erb") {
    identifiedLanguage = "html"
  } else if (extension == ".rake") {
    identifiedLanguage = "ruby"
  } else{
    languages.forEach((language) => {
      const {id, extensions} = language

      if (extensions.includes(extension)) {
        identifiedLanguage = id
      }
    })
  }

  return identifiedLanguage
}