<template>
  <div>
    <FormSerializer :model='model' :class='{"d-none": true}' />
    <Fullscreener height='30rem' @change='handleFullscreenChange' class='bg-light' ref='fullscreener'>
      <AssetDropzone :model='model'>
        <div class='row no-gutters w-100 h-100'>
          <div class='col-3 h-100 '>
            <Tray :model='model'/>
          </div>
          <div class='border-left col-9 h-100'>
            <Editor :model='model' @toggle-fullscreen='toggleFullscreen' />
          </div>
        </div>
      </AssetDropzone>
    </Fullscreener>
  </div>
</template>

<script>

import { wrapAssets } from "./utils"

import { EditorModel, Asset, ISByte } from "./models"

import FormSerializer from "./serialization/form_serializer.vue"

import AssetDropzone from "./asset_dropzone/asset_dropzone"
import Fullscreener from "./fullscreener/fullscreener.vue"

import Tray from "./tray/tray.vue"
import Editor from "./editor/editor.vue"

export default {
  components: { FormSerializer, Tray, Editor, AssetDropzone, Fullscreener },
  props: [ "controller", "editorData", "objectName", "sourceAttributeName", "assetsAttributeName", "i18n", "directUploadURL" ],
  data() {
    const model = new EditorModel()
    const assets = Asset.order(wrapAssets(this.editorData.assets || []))

    model.byte = new ISByte(this.editorData.source)
    model.assets = assets
    model.i18n = this.i18n
    model.directUploadURL = this.directUploadURL

    model.serializationOptions.objectName = this.objectName
    model.serializationOptions.sourceAttributeName = this.sourceAttributeName
    model.serializationOptions.assetsAttributeName = this.assetsAttributeName

    model.enterSourceEditorWithByte()

    return { model }
  },
  mounted() {
    this.controller.model = this.model
  },
  methods: {
    handleFullscreenChange(active) {
      this.model.fullscreen.active = active
    },
    toggleFullscreen() {
      this.$refs.fullscreener.toggle()
    }
  }
}

</script>
