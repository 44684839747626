export function splitPath(path) {
  const pathSplitIdx = (path || "").lastIndexOf("/")

  const dirName = path.substr(0, pathSplitIdx)
  const baseName = path.substr(pathSplitIdx + 1)

  const extIdx = baseName.lastIndexOf(".")
  const extName = baseName.substr(extIdx)


  return { baseName, dirName, extName }
}