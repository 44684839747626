import { splitPath } from "../utils/path_utils"

import { Asset } from "./asset"

const IMAGE_TYPES_MATCHER = /^(image\/)/

export class BlobAsset extends Asset {
  static load_from_api(attributes) {
    let attrs = {...attributes}

    delete attrs.assetable_type

    return new BlobAsset(attrs)
  }

  constructor(attributes) {
    super(attributes)

    this.content_type = attributes.content_type || ""

    this.file = attributes.file
    this.url  = attributes.url
    this.uploadId = null
  }

  get type() { throw "No longer supported" }

  isBlob() { return true }

  isImage() {
    return this.content_type.match(IMAGE_TYPES_MATCHER)
  }

  requiresUpload() { return !!this.file && !this.isUploaded() }
  isUploaded() { return !!this.uploadId }

  apiType() { return "BlobAsset" }

  getURLPromise() {
    if (this.url) {
      return new Promise((resolve, reject) => resolve(this.url))
    } else if (this.file) {
      return this.dataUrlPromise().then((url) => this.url = url)
    }
  }

  dataUrlPromise() {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.readAsDataURL(this.file)

      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
    })
  }
}