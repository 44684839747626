<template>
  <div>
    <span>{{ inputName }}</span><br />
    <textarea :name='inputName'>{{ value }}</textarea>
  </div>

</template>

<script>

export default {
  props: [ "keyPath", "value" ],
  computed: {
    inputName() {
      const wrappedKeys = [...this.keyPath].map((key, i) => {
        if (i == 0) {
          return key
        } else {
          return `[${key}]`
        }
      })

      return wrappedKeys.join("")
    }
  }
}

</script>