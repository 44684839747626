import { Controller } from "@hotwired/stimulus"

const MODAL_CONTROLLER_IDENTIFIER = "bytes-selector-modal"
const NESTED_FIELDS_CONTROLLER_IDENTIFIER = "nested-fields"

const ID_SELECTOR = "[data-target~='bytes-selector.byteId']"
const DESCRIPTION_SELECTOR = "[data-target~='bytes-selector.byteDescription']"

export default class BytesSelectorController extends Controller {
  static targets = ["nestedFields"]

  get nestedFieldsController() { return this.application.getControllerForElementAndIdentifier(this.nestedFieldsTarget, NESTED_FIELDS_CONTROLLER_IDENTIFIER) }

  get modalTargetId() { return this.data.get("modal-id") }
  get modalTarget() { return document.getElementById(this.modalTargetId) }
  get modalController() { return this.application.getControllerForElementAndIdentifier(this.modalTarget, MODAL_CONTROLLER_IDENTIFIER) }

  openModal(e) {
    e.preventDefault()

    this.modalController.open()
  }

  // =================
  // = Item Handling =
  // =================

  addItem(item) {
    if (this.itemExistsWithId(item.id)) {
      this.bumpItemWithId(item.id)
    } else {
      this.appendItem(item)
    }
  }

  itemExistsWithId(id) {
    return this.findElementForId(id) != null
  }


  appendItem(item) {
    const element = this.nestedFieldsController.add()
    const idInput = element.querySelector(ID_SELECTOR)
    const descriptionElement = element.querySelector(DESCRIPTION_SELECTOR)

    idInput.value = item.id
    descriptionElement.innerHTML = item.rendered_description
  }

  bumpItemWithId(id) {
    const element = this.findElementForId(id)
    const clone = element.cloneNode(true)
    clone.classList.add("byte-selector-bump")

    element.parentNode.replaceChild(clone, element);
  }


  findElementForId(id) {
    const activeItemElements = this.nestedFieldsController.activeItems

    let element = null
    activeItemElements.forEach((el) => {
      if (el.querySelector(ID_SELECTOR).value == id) {
        element = el
      }
    })

    return element
  }

}