<template>
  <div class='d-flex flex-column h-100 overflow-auto'>

    <div class='list-group list-group-flush'>
      <TrayHeader>
        <strong>{{ model.translate("edit_your_byte") }}</strong>
      </TrayHeader>

      <TrayItem
        :active='currentEditable == model.byte'
        @click='handleByteClicked'>
        <i class='fa fa-cookie-bite'></i>
        {{ model.translate("source") }}
      </TrayItem>

      <TrayHeader>
        <div class='row justify-content-between align-items-center'>
          <div class='col-auto'>
            <strong>{{ model.translate("additional_assets") }}</strong>
            <span class='badge badge-primary'>{{ activeAssets.length }}</span>
          </div>
          <div class='col-auto'>
            <a href='#' class='btn btn-sm btn-light' @click.prevent='handleAddClicked'>
              <i class='fa fa-plus'></i>
            </a>
          </div>
        </div>
      </TrayHeader>

      <AssetTrayItem
        v-for='asset in orderedAssets'
        :asset='asset'
        :active='currentEditable == asset'
        :key='asset.id'
        @click='handleAssetClicked(asset)'
        @dblclick.native='handleAssetDoubleClicked(asset)'
        @destroy='handleAssetDestroy(asset)' />

    </div>
  </div>
</template>

<script>

import { Asset, FileManager } from "../models"

import AssetTrayItem from "./asset_tray_item.vue"
import TrayItem from "./tray_item.vue"
import TrayHeader from "./tray_header"

export default {
  components: { TrayItem, AssetTrayItem, TrayHeader },
  props: [ "model" ],
  computed: {
    currentEditable(){ return this.model.currentEditable },
    activeAssets() { return this.model.activeAssets },
    orderedAssets() { return Asset.order(this.activeAssets) }
  },
  methods: {
    handleByteClicked() {
      this.model.enterSourceEditorWithByte()
    },

    handleAssetClicked(asset) {
      this.model.enterShowWithAsset(asset)
    },

    handleAssetDoubleClicked(asset) {
      this.model.enterEditAsset(asset)
    },

    handleAddClicked() {
      this.model.enterNewAsset()
    },

    handleAssetDestroy(asset) {
      this.model.destroyAssetAndEnterByteEditMode(asset)
    },
  }
}

</script>