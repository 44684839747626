import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class SortableController extends Controller {
  static targets = ["items", "position"]

  get draggable() { return this.data.get("draggable") }
  get animation() { return parseInt(this.data.get("animation")) }
  get handle() { return this.data.get("handle") }
  get ghostClass() { return this.data.get("ghostClass") }

  get sortableTarget() { return this.hasItemsTarget ? this.itemsTarget : this.element }

  get sortableOptions() {
    const { animation, draggable, handle, ghostClass } = this

    const options = {
      onEnd: () => this.updateItemPositions(),
      // Safari 13.0.x Bug: https://github.com/SortableJS/Sortable/issues/1571
      forceFallback: true,
    }

    if (animation) {
      options.animation = animation
    }

    if (ghostClass) {
      options.ghostClass = ghostClass
    }

    if (draggable) {
      options.draggable = draggable
    }

    if (handle) {
      options.handle = handle
    }

    return options
  }

  connect() {
    this.sortable = new Sortable(this.sortableTarget, this.sortableOptions)
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy()
      this.sortable = null
    }
  }

  updateItemPositions() {
    this.positionTargets.forEach((input, idx) => input.value = idx)
  }
}