import { Controller } from '@hotwired/stimulus'
import { monacoLazyPromise } from "../vendor/monaco"

export default class SourceEditorController extends Controller {
  static targets = ["input", "editor"]

  get language() { return this.data.get("language") || "ruby" }
  get options() { return JSON.parse(this.data.get("options")) || {} }

  initialize() {
    this.editor = null
  }

  connect() {
    monacoLazyPromise()
      .then((monaco) => {
        this.editor = monaco.editor.create(this.editorTarget, this.editorOptions())
        this.editor.onDidChangeModelContent(this.updateInputValue.bind(this))
      })
  }

  disconnect() {
    if (this.editor) {
      this.editor.dispose()
    }
  }

  // ===========
  // = Actions =
  // ===========

  layout() {
    if (this.editor) {
      this.editor.layout()
    }
  }

  // ==================
  // = Initialization =
  // ==================

  editorOptions() {
    const options = {
      value: this.inputTarget.value,
      language: this.language,
      tabSize: 2,
      autoIndent: "full"
    }

    if (this.options.minimap === false){
      options.minimap = { enabled: false }
    }

    return options
  }

  // =========================
  // = Input Synchronization =
  // =========================

  updateInputValue(change) {
    this.inputTarget.value = this.editor.getValue()
  }
}