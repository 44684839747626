import { splitPath } from "../utils/path_utils"

let uid = 1

export class Asset {
  static order(assets) {
    const key = "path"
    const cmp = function(a, b){
        if (a[key] < b[key]) {
            return -1;
        } else if (a[key] > b[key]) {
            return 1;
        }
        return 0;
    }

    return assets.sort(cmp)
  }

  constructor(attributes) {
    if (!attributes) {
      attributes = {}
    }

    this._uid = uid++
    this.id = attributes.id
    this.path = attributes.path
    this._destroy = false
  }

  isByte() { return false }
  isPersisted() { return !!this.id }
  isPathEditable() { return true }
  isDestroyable() { return true }

  isScript() { return false }
  isBlob() { return false }
  isDestroyed() { return this._destroy }
  apiType() { throw "Not yet implemented"}

  get path() {
    return this._path
  }

  set path(path) {
    this._path = path
    this.language = null
    this.splitPath()
  }

  splitPath() {
    const { baseName, dirName, extName } = splitPath(this._path)

    this.baseName = baseName
    this.dirName = dirName
    this.extName = extName
  }


}