<template>
  <Dropzone @drop='handleDrop'>
    <slot />
    <template v-slot:dropzone>
      <div class='dropzone p-3'>
        <div class='h3 text-muted mb-0 text-center'>{{ model.translate("drop_here") }}</div>
      </div>
    </template>
  </Dropzone>
</template>

<script>

import Dropzone from "./dropzone"
import { FileManager } from "../models"

export default {
  components: { Dropzone },
  props: [ "model" ],
  computed: {
    fileManager() { return this.model.fileManager }
  },
  methods: {
    handleDrop(files) {
      files.forEach((file) => this.addFile(file))
    },

    addFile(file) {
      this.fileManager.buildAssetFromFile(file).then((asset) => {
        asset.path = this.model.getUniquePath(asset.path)

        this.model.addAssetAndEnterShow(asset)
      })
    }
  }
}

</script>

<style scoped>

.dropzone {
  border: 5px dashed #bbb;
  border-radius: 20px;
  height: 50%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
