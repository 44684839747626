export default class Fullscreener {
  constructor(element) {
    this.element = element
    this.fullscreenChangedListeners = []
  }


  supported() {
    return document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullscreenEnabled ||
      document.msFullscreenEnabled
  }

  enabled() {
    return !!(document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement)
  }

  addEventListener(method) {
    document.addEventListener("fullscreenchange", method, false);
    document.addEventListener("mozfullscreenchange", method, false);
    document.addEventListener("webkitfullscreenchange", method, false);
    document.addEventListener("msfullscreenchange", method, false);
  }

  removeEventListener(method) {
    document.removeEventListener("fullscreenchange", method, false);
    document.removeEventListener("mozfullscreenchange", method, false);
    document.removeEventListener("webkitfullscreenchange", method, false);
    document.removeEventListener("msfullscreenchange", method, false);
  }

  toggle() {
    if (this.enabled()) {
      this.disable()
    } else {
      this.enable()
    }
  }

  enable() {
    const { element } = this

    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }
  }

  disable() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  }

}