import { Controller } from '@hotwired/stimulus'

import { lazyVue } from '../vendor/vue'
import ByteEditor from "../byte_editor"

export default class ByteEditorController extends Controller {
  static targets = ["editor"]

  get formTarget() { return this.element.closest("form") }

  get editorData() { return JSON.parse(this.data.get("data")) }
  get i18n() { return JSON.parse(this.data.get("i18n")) }
  get objectName() { return this.data.get("object-name") }
  get sourceAttributeName() { return this.data.get("source-attribute-name") }
  get assetsAttributeName() { return this.data.get("assets-attribute-name") }
  get directUploadURL() { return this.data.get("direct-upload-url") }

  connect() {
    this.model = null
    lazyVue().then((Vue) => this.setup(Vue))
    this.installFormListener()
  }

  disconnect() {
    this.uninstallFormListener()
  }

  setup(Vue) {
    this.editor = new Vue({
      el: this.editorTarget,
      render: (h) => h(ByteEditor, {
        attrs: {
          controller: this,
          editorData: this.editorData,
          i18n: this.i18n,
          objectName: this.objectName,
          sourceAttributeName: this.sourceAttributeName,
          assetsAttributeName: this.assetsAttributeName,
          directUploadURL: this.directUploadURL
        }
      })
    })
  }

  installFormListener() {
    this.formTarget.addEventListener("submit", this.uploadBlobs)
  }

  uninstallFormListener() {
    this.formTarget.removeEventListener("submit", this.uploadBlobs)
  }

  uploadBlobs = (e) => {
    const uploadManager = this.model.uploadManager
    if (uploadManager.hasPendingUploads()) {
      e.preventDefault()

      uploadManager.uploadNewFiles().then(() => {
        e.target.requestSubmit()
      })
    }
  }

}