<template>
  <div class='p-3 bg-white h-100 d-flex flex-column'>
    <div>
      <h6 class='mb-0'><slot name='header' /></h6>
      <hr />
    </div>
    <div class='flex-grow-1'>
      <slot />
    </div>

    <div>
      <hr />
      <slot name='footer' />
    </div>
  </div>
</template>

<script>

export default {}

</script>