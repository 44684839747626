<template>
  <div :style='{ height: currentHeight }'>
    <slot />
  </div>
</template>

<script>

import { Fullscreener } from "../utils"

export default {
  props: [ "height" ],
  computed: {
    currentHeight() { return this.active ? "100vh" : this.height }
  },
  data() {
    return {
      active: false,
      fullscreener: null,
      fullscreenListener: null,

    }
  },
  mounted() {
    this.installFullscreener()
    this.emitChangeEvent()
  },

  beforeDestroy() {
    this.uninstallFullscreener()
  },

  methods: {
    installFullscreener() {
      const fullscreener = new Fullscreener(this.$el)

      this.fullscreenListener = () => this.emitChangeEvent()

      fullscreener.addEventListener(this.fullscreenListener)
      this.fullscreener = fullscreener
    },

    uninstallFullscreener() {
      if (this.fullscreenListener) {
        this.fullscreener.removeEventListener(this.fullscreenListener)
        this.fullscreenListener = null
      }
    },

    toggle() {
      this.fullscreener.toggle()
    },

    emitChangeEvent() {
      this.$emit("change", this.fullscreener.enabled())
    },

    helloWorld() {
      alert("!!!")
    }
  }
}

</script>
