<template>
  <div class='input-group input-group-sm'>
    <select class='custom-select' @input='handleChange' ref='select' :disabled='disabled'>
      <option v-for='language in languages' :value='language.id' :selected='value == language.id'>{{ language.name }}</option>
    </select>
  </div>
</template>

<script>

import { monacoLazyPromise } from "../../../vendor/monaco"

export default {
  props: [ "value", "disabled" ],
  data() {
    return {
      languages: [],
      languagesLoaded: false
    }
  },
  mounted() {
    if (!this.languagesLoaded) {
      this.loadLanguages()
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() { this.selectedValue = this.value }
    }
  },
  methods: {
    loadLanguages() {
      monacoLazyPromise().then((monaco) => {
        const langs = monaco.languages.getLanguages()

        this.languages = langs.map((lang) => {
          const { id } = lang

          const name = lang.aliases[0] || lang.id

          return { id, name }
        })
      })
    },
    handleChange(e) {
      const value = this.$refs.select.value

      this.$emit("input", value)
    }
  }
}

</script>
