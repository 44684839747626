<template>
  <FormScreen>
    <template v-slot:header>
      {{ model.translate("update_asset") }}
    </template>

    <FormField
      :label='model.translate("path")'
      type='text'
      v-model='path'
      :error='validationErrorMessage'
      ref='field'
      @submit='handleSave' />

    <template v-slot:footer>
      <FormButtons @cancel='handleCancel' @save='handleSave' :model='model' />
    </template>
  </FormScreen>
</template>

<script>

import { PathValidator } from  "../models"

import FormScreen from "./form_screen"
import FormButtons from "../form/form_buttons.vue"
import FormField from "../form/form_field.vue"

export default {
  components: { FormButtons, FormField, FormScreen },
  props: [ "model" ],
  computed: {
    fieldName() { return `vue-field-${this._uid}` },
    editable() { return this.model.currentEditable },
    validationErrorMessage() { return this.pathValidator.validationErrorMessage(this.validationError) },
  },
  data() {
    return {
      path: "",
      pathValidator: new PathValidator(this.model),
      validationError: null,
    }
  },
  watch: {
    path() { this.validate() },
  },
  mounted() {
    this.loadPath()
    this.resetError()
    this.focusField()
  },
  methods: {
    focusField() {
      this.$refs.field.focus()
    },

    handleCancel() {
      this.model.enterShowWithAsset(this.editable)
    },

    handleSave() {
      if (!this.validate()) {
        this.$nextTick(() => alert("Please review errors"))
        return
      }

      this.editable.path = this.path
      this.editable.splitPath()

      this.model.enterShowWithAsset(this.editable)
    },

    validate() {
      this.validationError = this.pathValidator.validate(this.path)
      return !this.validationError
    },

    loadPath() {
      this.path = this.model.currentEditable.path
    },

    resetError() {
      this.validationError = null
    }
  },
}

</script>