import { ScriptAsset, BlobAsset } from "../models"

const SOURCE_TYPES = /^(text\/|application\/json|application\/(x-)?(javascript|yaml))/
const SOURCE_EXTENSIONS = /((\.html)?\.erb|\.vue|\.rake)$/

export class FileManager {
  async buildAssetFromFile(file) {
    if (this.isScriptFile(file)) {
      return await this.buildScriptAsset(file)
    } else {
      return await this.buildBlobAsset(file)
    }
  }

  isScriptFile(file) {
    return file.type.match(SOURCE_TYPES) || file.name.match(SOURCE_EXTENSIONS)
  }

  async buildScriptAsset(file) {
    const text = await file.text()

    return new ScriptAsset({
      path: file.name,
      source: text
    })
  }

  async buildBlobAsset(file) {
    return new BlobAsset({
      path: file.name,
      file: file,
      content_type: file.type
    })
  }
}