<template>
  <MonacoEditor v-model='editable.source' :language='language' class='h-100' ref='monacoEditor' />
</template>

<script>

import MonacoEditor from "./monaco"
import { monacoLazyPromise, languageFromExtension } from "../../../vendor/monaco"


export default {
  components: { MonacoEditor },
  props: [ "model" ],
  computed: {
    editable() { return this.model.currentEditable },
    isDestroyable() { return this.model.currentEditable.isDestroyable() },
    isFullscreen() { return this.model.fullscreen.active },
    isEditable() { return !!this.path },
    fullscreenSupported() { return this.model.fullscreener.supported() },
    language() { return this.editable?.language },
  },
  watch: {
    editable: {
      immediate: true,
      handler: "ensureLanguage"
    },
    isFullscreen: {
      handler() { this.$refs.monacoEditor.layout() }
    }
  },
  methods: {
    handleClose() {
      this.model.enterWelcome()
    },
    handleEdit() {
      this.model.enterEditAsset(this.editable)
    },
    ensureLanguage() {
      const { editable } = this

      if (!editable.language && editable.extName) {
        this.guessLanguage(editable.extName)
          .then((language) => {
            console.log("Updating language to: ", language, editable)
            editable.language = language
          })
      }
    },

    guessLanguage(extension) {
      return monacoLazyPromise().then((monaco) => {
        return languageFromExtension(monaco, extension)
      })
    }
  }
}

</script>