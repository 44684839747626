export class PathValidator {
  constructor(model) {
    this.editorModel = model
    this.asset = null
  }

  validationErrorMessage(identifier) {
    switch (identifier) {
    case "blank": return `must be present`
    case "not_unique": return `has already been taken`
    }
  }

  validate(path) {
    if (!this.validatePresence(path)) {
      return "blank"
    } else if (!this.validateUniqueness(path)) {
      return "not_unique"
    }
  }


  validatePresence(path) {
    return path != ""
  }

  validateUniqueness(path) {
    let isUnique = true
    const currentAsset = this.editorModel.currentEditable

    this.editorModel.assets.filter((asset) => !asset.isDestroyed()).forEach((asset) => {
      if (currentAsset && currentAsset._uid == asset._uid){ return }

      if (path == asset.path) {
        isUnique = false
      }
    })

    return isUnique
  }
}