<template>
  <button type='button' @click.prevent="$emit('click')" class='btn btn-sm btn-danger'>
    <i class="fa fa-trash"></i>
  </button>
</template>

<script>

export default {}

</script>