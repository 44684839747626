<template>
  <TrayItem
    size='small'
    @click='$emit("click")'
    :active='active'>
      <small>
        {{ title }}
        <small :class='{"d-block": true, "text-muted": !active}' v-if='subtitle'>{{ subtitle }}</small>
      </small>
  </TrayItem>
</template>

<script>

import TrayItem from "./tray_item.vue"

export default {
  components: { TrayItem },
  props: [ "asset", "active" ],
  computed: {
    title() { return this.asset.baseName },
    subtitle() { return this.asset.dirName },
  },
}

</script>