<template>
  <div class='h-100 d-flex flex-column'>
    <Toolbar>
      <div class='mr-auto'>
        <LanguageSelector v-model='editable.language' v-if='isScript' :disabled='isByte' />
      </div>

      <div class='ml-3' v-if='path'>
        {{ model.translate("path") }}: {{ path }}
      </div>

      <div class='ml-3'>
        <EditButton @click="handleEdit" v-if='isPathEditable' />
        <DestroyButton @click="handleDestroy" v-if='isDestroyable' />
        <FullscreenButton :model='model' @toggle="$emit('toggle-fullscreen')" />
      </div>
    </Toolbar>

    <ScriptEditor v-if='isScript' :model='model' />
    <BlobPreview v-else-if='isBlob' :model='model' />
  </div>
</template>
<script>

import Toolbar from "./show_screen/toolbar"

import FullscreenButton from "../buttons/fullscreen_button.vue"
import EditButton from "../buttons/edit_button.vue"
import DestroyButton from "../buttons/destroy_button.vue"

import ScriptEditor from "./show_screen/script_editor"
import BlobPreview from "./show_screen/blob_preview"
import LanguageSelector from "./show_screen/language_selector"

export default {
  props: [ "model" ],
  components: { Toolbar, LanguageSelector, ScriptEditor, BlobPreview, FullscreenButton, EditButton, DestroyButton },
  computed: {
    editable() { return this.model.currentEditable },

    path() { return this.editable.path },
    language() { return this.editable.language },

    isByte() { return this.editable.isByte() },
    isScript() { return this.editable.isScript() },
    isBlob() { return this.editable.isBlob() },
    isPathEditable() { return this.editable.isPathEditable() },
    isDestroyable() { return this.editable.isDestroyable() },
  },
  methods: {
    handleEdit() {
      this.model.enterEditAsset(this.editable)
    },
    handleDestroy() {
      if (confirm(this.model.translate("confirm_destroy"))) {
        this.model.destroyAssetAndEnterByteEditMode(this.editable)
      }
    }
  }
}

</script>
