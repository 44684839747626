<template>
  <button type='button' :class='["btn", themeClass, sizeClass, { "active": active}]' v-bind='$attrs' v-on='$listeners'>
    <slot />
  </button>
</template>

<script>

import { validatedTheme, mappedValue } from "./utils"

const sizes = {
  small: "btn-sm",
  large: "btn-lg",
  default: ""
}

export default {
  props: {
    outline: {
      type: Boolean
    },
    theme: {
      type: String,
      default: "primary"
    },
    size: {
      type: String,
      default: "default"
    },
    active: Boolean
  },
  computed: {
    themeClass() {
      let parts = []
      parts.push("btn")
      if (this.outline)
        parts.push("outline")

      parts.push(validatedTheme(this.theme))


      return parts.join("-")
    },
    sizeClass() {
      return mappedValue(sizes, this.size)
    },
  }
}
</script>