<template>
  <div class='list-group-item bg-light text-dark rounded-top'>
    <slot />
  </div>
</template>

<script>

export default {

}

</script>
