import { Controller } from '@hotwired/stimulus'

import $ from 'jquery'

const TOOLS_EDITOR_NAME = "tools-editor"

export default class ToolsEditorModalController extends Controller {
  static targets = ["modal", "item"]

  get toolsEditorTargetId() { return this.data.get("editor-id") }
  get toolsEditorTarget() { return document.getElementById(this.toolsEditorTargetId) }
  get toolsEditor() { return this.application.getControllerForElementAndIdentifier(this.toolsEditorTarget, TOOLS_EDITOR_NAME) }

  get usedToolIds() { return this.toolsEditor.usedToolIds }

  connect() {
    $(this.element).on(this.namespacedEvent("show.bs.modal"), () => this.updateToolEnabledState())
  }

  disconnect() {
    $(this.element).off(this.namespacedEvent("show.bs.modal"))
  }

  updateToolEnabledState() {
    const usedIds = this.usedToolIds

    const usedItemClasses = ["bg-info", "text-white", "disabled"]

    this.itemTargets.forEach((item) => {
      const toolData = this.parseToolData(item)
      const used = usedIds.includes(toolData.id)

      if (used) {
        usedItemClasses.forEach((cssClass) => item.classList.add(cssClass))
      } else {
        usedItemClasses.forEach((cssClass) => item.classList.remove(cssClass))
      }
    })
  }

  namespacedEvent(named) {
    return `${named}.tools-editor-modal`
  }

  selectTool(e) {
    e.preventDefault()

    const itemElement = e.target.closest("[data-target~='tools-editor-modal.item']")
    const toolData = this.parseToolData(itemElement)

    this.toolsEditor.addTool(toolData)
    this.close()
  }

  parseToolData(element) {
    const toolElement = element

    return JSON.parse(toolElement.dataset.tool)
  }

  open() {
    $(this.modalTarget).modal("show")
  }

  close() {
    $(this.modalTarget).modal("hide")
  }

}