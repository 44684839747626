import { Controller } from '@hotwired/stimulus'
import { monacoLazyPromise, languageFromExtension } from "../vendor/monaco"

const DEFAULT_LANGUAGE = "plaintext"

export default class SourceFormatterController extends Controller {
  get language() { return this.data.get("language") }
  get extension() { return this.data.get("extension") }


  get colorized() { return this.data.get("colorized") == "true" }
  set colorized(boolean) { this.data.set("colorized", boolean ? "true" : "false") }


  connect() {
    if (!this.colorized) {
      this.colorize()
      this.colorized = true
    }
  }

  colorize() {

    monacoLazyPromise().then((monaco) => {
      this.monaco = monaco

      const language = this.getLanguage()
      monaco.editor.colorizeElement(this.element, {mimeType: language })
    })
  }

  getLanguage(monaco) {
    return this.language || this.languageFromExtension() || DEFAULT_LANGUAGE
  }

  languageFromExtension() {
    if (this.extension){
      return languageFromExtension(this.monaco, this.extension)
    }
  }
}