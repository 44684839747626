import { Controller } from '@hotwired/stimulus'

const TOOLS_EDITOR_IDENTIFIER = "tools-editor"

export default class ToolsEditorItemController extends Controller {
  static targets = ["id", "destroy", "title", "toolId", "handle"]

  get id() { return this.idTarget.value }

  set title(value) { this.titleTarget.innerText = value }

  get toolId() { return this.toolIdTarget.value }
  set toolId(value) { this.toolIdTarget.value = value }

  get destroy() { return this.destroyTarget.value == "true" }
  set destroy(value) { this.destroyTarget.value = (!!value).toString() }

  get isPersisted() { return this.id != "" }
  get isActive() { return !this.destroy }

  get toolsEditorElement() { return this.element.closest(`[data-controller~=${TOOLS_EDITOR_IDENTIFIER}]`) }
  get toolsEditor() { return this.application.getControllerForElementAndIdentifier(this.toolsEditorElement, TOOLS_EDITOR_IDENTIFIER) }

  get attributes() { return this.toolsEditor.newToolAttributes }

  connect() {
    this.loadAttributes()
  }

  // ===========
  // = Loading =
  // ===========

  loadAttributes() {
    const attributes = this.toolsEditor.getNewToolAttributes()

    if (attributes) {
      this.toolId = attributes.id
      this.title = attributes.title
    }
  }
}