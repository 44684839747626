<template>
  <div class='row justify-content-between'>
    <div class='col-auto'>
      <a href='#' @click.prevent='$emit("cancel")' class='btn btn-light'>{{ model.translate("cancel") }}</a>
    </div>
    <div class='col-auto'>
      <a href='#' @click.prevent='$emit("save")' class='btn btn-primary'>{{ model.translate("save") }}</a>
    </div>
  </div>
</template>

<script>

export default {
  props: [ "model" ],
}

</script>