<template>
  <a href='#' @click.prevent='$emit("toggle")' class='btn btn-sm btn-light'>
    <span :class='{ "d-none": enabled }'>
      <i class="fa fa-expand"></i>
    </span>
    <span :class='{ "d-none": !enabled }'>
      <i class="fa fa-compress"></i>
    </span>

  </a>
</template>

<script>

export default {
  props: [ "model" ],
  computed: {
    fullscreener() { return this.model.fullscreener },
    enabled() { return this.model.fullscreen.active },
  },
  methods: {
    toggleFullscreen() {
      this.fullscreener.toggle()
    }
  }
}
</script>