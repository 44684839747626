import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import Rails from '@rails/ujs'

import debounce from 'lodash.debounce'

const BYTES_SELECTOR_CONTROLLER_IDENTIFIER = "bytes-selector"
const AUTOSEACH_DELAY = 250

export default class BytesSelectorModalController extends Controller {
  static targets = [ "template", "results", "noResults", "startTyping", "searchField" ]

  get bytesSelectorId() { return this.data.get("selector-id") }
  get bytesSelectorTarget() { return document.getElementById(this.bytesSelectorId) }
  get bytesSelectorController() { return this.application.getControllerForElementAndIdentifier(this.bytesSelectorTarget, BYTES_SELECTOR_CONTROLLER_IDENTIFIER) }

  get $modal() { return $(this.element) }

  initialize() {
    this.setupDebouncedAutosearch()
  }

  connect() {
    this.reset()

    $(this.element).on(this.namespacedEvent("shown.bs.modal"), () => {
      this.focusSearch()
    })
  }

  open() {
    this.reset()
    this.$modal.modal("show")
  }

  close() {
    this.$modal.modal("hide")
  }

  focusSearch() {
    this.searchFieldTarget.focus()
  }

  delayAutosearch() {
    this.debouncedAutosearch()
  }

  cancelAutosearch() {
    this.cancelDebouncedAutosearch()
  }

  performSearch(e) {
    e.preventDefault()
    this.sendSearch()
  }


  showSearchResults(e) {
    const data = e.detail[0]

    this.cleanResults()
    this.displayResults(data)
  }

  selectResult(e) {
    e.preventDefault()

    const itemElement = e.target.closest("[data-target~='bytes-selector-modal.item']")
    const resultJSON = itemElement.dataset.result
    const result = JSON.parse(resultJSON)

    this.bytesSelectorController.addItem(result)

    this.close()
  }

  // ===================
  // = Results Display =
  // ===================

  cleanResults() {
    let firstChild;
    while (firstChild = this.resultsTarget.firstChild) {
      firstChild.remove()
    }
  }

  displayResults(results) {
    if (results.length) {
      this.showState("results")

      results.forEach((result) => {
        const itemTemplate = this.templateTarget.content.cloneNode(true)

        const item = itemTemplate.querySelector("[data-target~='bytes-selector-modal.item']")
        const description = itemTemplate.querySelector("[data-target~='bytes-selector-modal.description']")

        description.innerHTML = result.rendered_description
        item.dataset.result = JSON.stringify(result)

        this.resultsTarget.appendChild(itemTemplate)
      })
    } else {
      this.showState("noResults")
    }
  }

  // =============
  // = Searching =
  // =============

  sendSearch() {
    const form = this.searchFieldTarget.closest("form")

    Rails.fire(form, "submit")
  }


  setupDebouncedAutosearch() {
    this.debouncedAutosearch = debounce(() => {
      this.sendSearch()
    }, AUTOSEACH_DELAY)
  }

  cancelDebouncedAutosearch() {
    this.debouncedAutosearch.cancel()
  }

  // ==================
  // = Handling State =
  // ==================

  reset() {
    this.searchFieldTarget.value = ""
    this.showState("startTyping")
  }


  showState(named) {
    let activeElement = this.getActiveElementForState(named)
    let statefulElements = [this.resultsTarget, this.noResultsTarget, this.startTypingTarget]

    statefulElements.forEach((el) => {

      if (el == activeElement) {
        el.classList.remove("d-none")
      } else {
        el.classList.add("d-none")
      }

    })
  }

  getActiveElementForState(named) {
    switch (named) {
    case "results":
      return this.resultsTarget
      break;
    case "noResults":
      return this.noResultsTarget
      break;
    case "startTyping":
      return this.startTypingTarget
      break;
    }
  }

  // ===========
  // = Helpers =
  // ===========

  namespacedEvent(named) {
    return `${named}.bytes-selector-modal`
  }

}