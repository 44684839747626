<template>
  <img :src='srcURL' v-bind='$attrs' @dragstart.prevent>
</template>

<script>

export default {
  props: [ 'blob' ],
  data() {
    return {
      srcURL: null,
    }
  },
  watch: {
    blob: {
      handler() {
        this.blob.getURLPromise().then((url) => this.srcURL = url)
      },
      immediate: true
    }
  }
}

</script>
