export class ISByte {
  constructor(source) {
    this.source = source
  }

  get language() { return "ruby" }

  isScript() { return true }
  isByte() { return true }

  isDestroyable() { return false }
  isPathEditable() { return false }
}