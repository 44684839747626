<template>
  <div class='w-100 h-100 d-flex align-items-center justify-content-center bg-light'>
    <div class='h2'>
      welcome
    </div>
  </div>

</template>

<script>

export default {
  props: [ "model" ],

}

</script>