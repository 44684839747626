export class PathManager {
  constructor(model) {
    this.model = model
  }

  get activeAssets() { return this.model.activeAssets }

  getUniquePath(path) {
    if (!path) { return }
    if (this.isUniqueFilename(path)) { return path }

    const { baseName, dirName, extName } = splitPath(path)
    const baseNameWithoutExt = baseName.replace(extName, "")

    let dirPath = ""
    if (dirName) {
      dirPath = `${dirName}/`
    }

    let i = 2
    while (true) {
      const pathWithNumber = `${dirPath}${baseNameWithoutExt}-${i}${extName}`

      if (this.isUniqueFilename(pathWithNumber)) {
        return pathWithNumber
      }

      i++
    }
  }

  isUniqueFilename(path) {
    return !this.activeAssets.filter((asset) => asset.path == path).length
  }
}