import { Controller } from '@hotwired/stimulus'

class ManualStrategy {
  copy(value) {
    alert(`Clipboard not supported. Please copy manually:\n\n${value}`)
  }
}

class ClipboardStrategy {
  constructor(fallbackStrategy) {
    this.fallbackStrategy = fallbackStrategy
  }

  copy(value) {
    Promise.resolve(value)
      .then((val) => new Blob([val], { type: "text/plain" }) )
      .then((blob) => new ClipboardItem({ "text/plain":  blob }))
      .then((item) => navigator.clipboard.write([item]))
      .catch((e) => this.fallbackStrategy.copy(value))
  }

  prepareClipboardData(value) {
    const promise = Promise.resolve(value)
      .then((val) => new Blob([val], { type: "text/plain" }) )
      .then((blob) => new ClipboardItem({ "text/plain":  blob }))
      .then((item) => [item])

    return promise
  }
}

export default class ClipboardController extends Controller {
  static targets = ["default", "success"]

  get value() { return this.data.get("value") }
  get delay() { return parseInt(this.data.get("delay")) || 100 }

  get stateTargets() {
    return {
      default: this.defaultTarget,
      success: this.successTarget
    }
  }

  initialize() {
    let strategy = new ManualStrategy()

    if (navigator.clipboard) {
      strategy = new ClipboardStrategy(strategy)
    }

    this.strategy = strategy
  }

  connect() {
    this.displayStatus("default")
  }

  copy(e) {
    e.preventDefault()

    this.strategy.copy(this.value)
    this.displaySuccess()
  }

  displaySuccess() {
    this.displayStatus("success")

    setTimeout(() => this.displayStatus("default"), this.delay)
  }

  displayStatus(named) {
    const targets = this.stateTargets

    Object.keys(targets).forEach((key) => {
      const target = targets[key]

      if (key == named) {
        target.style.display = "inline"
      } else {
        target.style.display = "none"
      }
    })
  }
}