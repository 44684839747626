<template>
  <div :class='["btn-group", sizeClass]' role='group' :aria-label='label' v-bind='$attrs'>
    <slot />
  </div>
</template>

<script>

import { mappedValue } from "./utils"

const sizes = {
  small: "btn-group-sm",
  default: "",
  large: "btn-group-lg"
}

export default {
  props: {
    label: String,
    size: { type: String, default: "default" },
  },
  computed: {
    sizeClass() {
      return mappedValue(sizes, this.size)
    }
  }
}

</script>