<template>

  <div>
    <div class='d-flex flex-wrap'>
      <SerializedInput :keyPath='[serializationOptions.objectName, serializationOptions.sourceAttributeName]' :value='model.byte.source' />

      <SerializedAsset
        v-for='asset, index in assets'
        :key='asset.id'
        :asset='asset'
        :key-path='[serializationOptions.objectName, serializationOptions.assetsAttributeName, index]'
        :index='index'
        />
    </div>
  </div>

</template>

<script>

import SerializedInput from "./serialized_input.vue"
import SerializedAsset from "./serialized_asset.vue"

export default {
  components: { SerializedInput, SerializedAsset },
  props: [ "model" ],
  computed: {
    assets() { return this.model.assets },
    serializationOptions() { return this.model.serializationOptions },
  }
}

</script>