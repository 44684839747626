<template>
  <div :class='["list-group-item list-group-item-action", { active: active }]' @click.pervent.stop='$emit("click")' role="button">
    <div class='d-flex justify-content-between align-items-center flex-nowrap w-100'>
      <div class="text-break flex-shrink-1 flex-grow-1">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: [ "title", "subtitle", "active", "size"],
}

</script>