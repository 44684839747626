import { splitPath } from "../utils/path_utils"

import { Asset } from "./asset"

export class ScriptAsset extends Asset {
  static load_from_api(attributes) {
    let attrs = {...attributes}

    delete attrs.assetable_type

    return new ScriptAsset(attrs)
  }

  constructor(attributes) {
    super(attributes)

    this.source = attributes.source || ""
    this.language = attributes.language
  }

  isScript() { return true }

  apiType() { return "ScriptAsset" }
}