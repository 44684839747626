<template>
  <FormScreen>
    <template v-slot:header>
      {{ model.translate("new_asset") }}
    </template>

    <FormField
      :label='model.translate("give_it_a_path")'
      type='text'
      v-model='path'
      :error='pathValidationErrorMessage'
      ref='pathInput'
      @submit='handleSave' />


    <FormField
      :label='model.translate("source_file")'
      type='file'
      :error='fileValidationErrorMessage'
      ref='fileInput'
      v-model='file'
      @submit='handleSave' />

    <template v-slot:footer>
      <FormButtons @cancel='handleCancel' @save='handleSave' :model='model' />
    </template>

  </FormScreen>
</template>

<script>

import { ScriptAsset, PathValidator, FileManager } from  "../models"

import FormButtons from "../form/form_buttons.vue"
import FormField from "../form/form_field.vue"
import FormScreen from "./form_screen"

import ButtonGroup from "../bootstrap/button_group"
import Button from "../bootstrap/button"

export default {
  components: { ButtonGroup, Button, FormScreen, FormButtons, FormField },
  props: [ "model" ],
  computed: {
    isScript() { return this.type == "source" },
    isBlob() { return this.type == "blob" },
    pathValidationErrorMessage() { return this.pathValidator.validationErrorMessage(this.pathValidationError) },
    fileValidationErrorMessage() { return "" },
    hasValidationError() { return this.pathValidationError || this.fileValidationError },
    hasAttachedFile() { return !!this.file },
    fileManager() { return this.model.fileManager }
  },
  data() {
    return {
      path: "",
      file: null,
      pathValidator: new PathValidator(this.model),
      pathValidationError: null,
      fileValidationError: null,
    }
  },
  mounted() {
    this.reset()
    this.focusField()
  },
  watch: {
    path() { this.validate() },
    file() {
      if (!this.path){
        this.path = this.model.getUniquePath(this.file.name)
      }
      this.validate()
    }
  },
  methods: {
    focusField() {
      this.$refs.pathInput.focus()
    },
    handleCancel() {
      this.model.enterSourceEditorWithByte()
    },

    async handleSave() {
      if (!this.validate()) {
        this.$nextTick(() => alert("Please review errors"))
        return
      }

      const asset = await this.buildAsset()

      this.model.addAssetAndEnterShow(asset)
    },

    validate() {
      this.fileValidationError = null
      this.pathValidationError = null

      if (this.path){
        this.pathValidationError = this.pathValidator.validate(this.path)
      }

      if (!this.path && !this.file) {
        this.pathValidationError = "blank"
      }

      return !this.hasValidationError
    },

    reset() {
      this.path = ""
    },

    async buildAsset() {
      if (this.hasAttachedFile) {
        const { file } = this

        const asset = await this.fileManager.buildAssetFromFile(file)

        if (this.path){
          asset.path = this.path
        }

        return asset
      } else {
        return new ScriptAsset({
          path: this.path,
          source: ""
        })
      }
    },
  },
}

</script>