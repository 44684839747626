<template>
  <div class='w-100 h-100 d-flex flex-column align-items-center justify-content-center bg-white'>
    <ImagePreview :blob="asset" class='rounded preview-image shadow' v-if='isImage' />
    <div class='small text-muted mt-2'>
      {{ asset.baseName }}
    </div>
  </div>
</template>

<script>

import ImagePreview from "./blob_preview/image_preview"

export default {
  components: { ImagePreview },
  props: [ "model" ],
  computed: {
    asset() {
      return this.model.currentEditable
    },
    isImage() { return this.asset.isImage() }
  }
}

</script>
<style>

.preview-image {
  max-height: 50%;
  max-width: 50%;
  object-fit: contain;
}

</style>