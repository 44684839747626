import { SerializationOptions } from "./serialization_options"

import { FileManager } from "../managers/file_manager"
import { PathManager } from "../managers/path_manager"
import { UploadManager } from "../managers/upload_manager"

import { splitPath } from "../utils/path_utils"

export class EditorModel {
  constructor() {
    this.mode = "welcome"
    this.byte = null
    this.currentEditable = null

    this.fullscreen = {
      active: false,
      supported: false
    }

    this.assets = []
    this.i18n = {}
    this.directUploadURL = null
    this.serializationOptions = new SerializationOptions()

    this.fileManager = new FileManager()
    this.pathManager = new PathManager(this)
    this.uploadManager = new UploadManager(this)
  }

  get activeAssets() { return this.assets.filter((asset) => !asset.isDestroyed() )}

  addAsset(asset) {
    this.assets.push(asset)
  }

  translate(key) {
    return this.i18n[key] || `Missing Translation Key: ${key}`
  }

  enterWelcome() {
    this.mode = "welcome"
    this.currentEditable = null
  }

  enterSourceEditorWithByte() {
    this.mode = "show"
    this.currentEditable = this.byte
  }

  enterShowWithAsset(asset) {
    this.mode = "show"
    this.currentEditable = asset
  }

  addAssetAndEnterShow(asset) {
    this.addAsset(asset)
    this.enterShowWithAsset(asset)
  }

  enterEditAsset(asset) {
    this.mode = "edit_asset"
    this.currentEditable = asset
  }

  enterNewAsset() {
    this.mode = "new_asset"
    this.currentEditable = null
  }

  destroyAssetAndEnterByteEditMode(asset) {
    if (asset.isPersisted()) {
      asset._destroy = true
    } else {
      const idx = this.assets.indexOf(asset)

      this.assets.splice(idx, 1)
    }

    this.enterSourceEditorWithByte()
  }

  getUniquePath(path) {
    return this.pathManager.getUniquePath(path)
  }

  performUploads() {
    this.uploadManager.uploadNewFiles()
  }
}