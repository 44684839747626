import Sortable from 'sortablejs'
import { Controller } from '@hotwired/stimulus'

import $ from 'jquery'

const ITEM_CONTROLLER_IDENTIFIER = "tools-editor-item"
const MODAL_CONTROLLER_IDENTIFIER = "tools-editor-modal"
const NESTED_FIELDS_CONTROLLER_IDENTIFIER = "nested-fields"

export default class ToolsEditorController extends Controller {
  static targets = ["count", "nestedFields", "item"]

  get itemControllers() { return this.itemTargets.map((el) => this.getItemControllerFor(el)) }
  get activeItemControllers() { return this.itemControllers.filter((controller) => controller && controller.isActive) }
  get usedToolIds() { return this.activeItemControllers.map((controller) => parseInt(controller.toolId)) }

  get count() { return parseInt(this.countTarget.innerText) }
  set count(value) { this.countTarget.innerText = value }

  get modalTargetId() { return this.data.get("modal-id") }
  get modalTarget() { return document.getElementById(this.modalTargetId) }
  get modalController() { return this.application.getControllerForElementAndIdentifier(this.modalTarget, MODAL_CONTROLLER_IDENTIFIER) }

  get nestedFieldsController() { return this.application.getControllerForElementAndIdentifier(this.nestedFieldsTarget, NESTED_FIELDS_CONTROLLER_IDENTIFIER) }

  connect() {
    this.newToolAttributes = null
  }

  openModal(e) {
    e.preventDefault()

    this.modalController.open()
  }

  addTool(toolAttributes) {
    this.newToolAttributes = {...toolAttributes, position: this.itemTargets.length - 1 }

    this.nestedFieldsController.add()
    this.incrementCount()
  }

  removedTool() {
    this.decrementCount()
    this.updateItemPositions()
  }

  // ===================
  // = Inserting tools =
  // ===================

  // Called from item controller
  getNewToolAttributes() {
    const { newToolAttributes } = this

    this.newToolAttributes = null

    return newToolAttributes
  }

  // =========
  // = Count =
  // =========

  incrementCount() {
    this.count++
  }

  decrementCount() {
    this.count--
  }

  // ===========
  // = Helpers =
  // ===========

  getItemControllerFor(element) {
    return this.application.getControllerForElementAndIdentifier(element, ITEM_CONTROLLER_IDENTIFIER)
  }
}