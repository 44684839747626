import { DirectUpload } from '@rails/activestorage'

export class UploadManager {

  get url() { return this.model.directUploadURL }

  get assetsToUpload() { return this.model.activeAssets.filter((asset) => asset.isBlob() && asset.requiresUpload()) }

  constructor(model) {
    this.model = model
  }

  hasPendingUploads() { return !!this.assetsToUpload.length }

  uploadNewFiles() {
    const { assetsToUpload } = this

    const uploads = assetsToUpload.map((asset) => this.upload(asset))

    return Promise.all(uploads)
  }


  upload(asset) {
    return this.performDirectUpload(asset.file).then((blob) => asset.uploadId = blob.signed_id)
  }

  performDirectUpload(file) {
    return new Promise((resolve, reject) => {
      const upload = new DirectUpload(file, this.url)
      console.log("Uploading file to", this.url, file)

      upload.create((error, blob) => {
        if (error) {
          reject(error)
        } else {
          resolve(blob)
        }
      })
    })
  }

  // performDummyUpload(asset) {
  //   return new Promise((resolve, reject) => {
  //     console.log("Performing dummy upload of ", asset)
  //
  //     asset.uploadId = "1234"
  //
  //     setTimeout(resolve, 1000)
  //   })
  // }

}