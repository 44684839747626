import { Controller } from '@hotwired/stimulus'

export default class NestedFieldsController extends Controller {
  static targets = ["template", "stash", "items", "insert"]

  get insertStrategy() { return this.data.get("add-strategy") || "append" }
  get insertionTarget() { return this.hasInsertTarget ? this.insertTarget : this.itemsTarget}

  get activeItems() { return this.itemsTarget.querySelectorAll(".nested-fields") }

  connect() {}

  add(e) {
    if (e) { e.preventDefault() }

    const element = this.prepareNewItemElement()

    this.insertNewItemElement(element)

    return element
  }

  remove(e) {
    e.preventDefault()
    const itemElement = e.target.closest(".nested-fields")

    if (this.isItemPersisted(itemElement)) {
      this.destroyAndStashAway(itemElement)
    } else {
      this.removeFromDOM(itemElement)
    }
  }

  isItemPersisted(element) {
    const idInput = element.querySelector("[data-target~='nested-fields.id']")

    return idInput.value !== ""
  }

  // ===============
  // = Insertation =
  // ===============

  generateItemId() {
    return new Date().getTime()
  }

  insertNewItemElement(element) {
    switch (this.insertStrategy) {
    case "append":
      this.insertionTarget.insertAdjacentElement("beforeend", element)
      break
    case "before":
      this.insertionTarget.insertAdjacentElement("beforebegin", element)
      break
    }
  }

  prepareNewItemElement() {
    const id = this.generateItemId()
    const itemElement = this.templateTarget.content.children[0].cloneNode(true)

    this.injectIdIntoAttribute(itemElement, "id", id)
    this.injectIdIntoAttribute(itemElement, "name", id)

    return itemElement
  }

  injectIdIntoAttribute(element, attribute, id) {
    const newRecordRegex = /__NEW_RECORD__/g
    const selector = `*[${attribute}*=__NEW_RECORD__]`

    element.querySelectorAll(selector).forEach((el) => {
      const currentValue = el.getAttribute(attribute)
      const injectedValue = currentValue.replace(newRecordRegex, id)

      el.setAttribute(attribute, injectedValue)
    })
  }

  // ===========
  // = Removal =
  // ===========

  destroyAndStashAway(element) {
    const destroyInput = element.querySelector("[data-target~='nested-fields.destroy']")
    destroyInput.value = "true"

    this.stashTarget.appendChild(element)
  }

  removeFromDOM(element) {
    element.remove()
  }
}